import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-attender-ailoader',
  templateUrl: './attender-ailoader.component.html',
  styleUrl: './attender-ailoader.component.scss'
})
export class AttenderAILoaderComponent {
  @Input() text: string = 'Analyzing by '
}
