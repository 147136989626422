import { Component, inject, Input } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-single-term',
  templateUrl: './view-single-term.component.html',
  styleUrl: './view-single-term.component.scss'
})
export class ViewSingleTermComponent {
  readonly nzModalData = inject(NZ_MODAL_DATA);
  terms: any = this.nzModalData.data;
  mediaBasePath = environment.mediaBaseUrl;
  constructor() { }

  ngOnInit(): void {
    console.log(this.terms);
  }
}
