// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-action {
  margin-top: 24px;
  text-align: right;
}

.step-content {
  margin-top: 20px;
}

button {
  margin-right: 8px;
}

.rating {
  padding: 20px;
  background: #fafafa;
}

.file-list {
  margin: 20px 0;
}
.file-list h6, .file-list nz-tag {
  margin-bottom: 10px;
}
.file-list .file-item-tag {
  padding: 5px;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/main/shared-component/job-complete-modal/job-complete-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;AACF;;AACA;EACE,gBAAA;AAEF;;AACA;EACE,iBAAA;AAEF;;AACA;EACE,aAAA;EACA,mBAAA;AAEF;;AACA;EACE,cAAA;AAEF;AADE;EACE,mBAAA;AAGJ;AADE;EACE,YAAA;EACA,eAAA;AAGJ","sourcesContent":[".steps-action {\n  margin-top: 24px;\n  text-align: right;\n}\n.step-content {\n  margin-top: 20px;\n}\n\nbutton {\n  margin-right: 8px;\n}\n\n.rating {\n  padding: 20px;\n  background: #fafafa;\n}\n\n.file-list {\n  margin: 20px 0;\n  h6,nz-tag {\n    margin-bottom: 10px;\n  }\n  .file-item-tag {\n    padding: 5px;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
