import { Component, Inject } from '@angular/core';
import { NZ_DRAWER_DATA, NzDrawerRef } from 'ng-zorro-antd/drawer';
import { ChatService } from 'src/app/common/services/chat.service';

@Component({
  selector: 'app-attender-chat-all-vendors',
  templateUrl: './attender-chat-all-vendors.component.html',
  styleUrl: './attender-chat-all-vendors.component.scss'
})
export class AttenderChatAllVendorsComponent {
  allVendors: any[] = [];
  searchText = '';
  isArchivedVisible = false;
  activeSellers: any[] = []; // Your active sellers data
  archivedSellers: any[] = []; // Your archived sellers data


  constructor(
    @Inject(NZ_DRAWER_DATA) private data: any,
    private chatService: ChatService,
    private nzDrawerRef: NzDrawerRef
  ) {
  }

  get filteredActiveSellers() {
    return this.activeSellers.filter(seller => seller.data?.seller_name.toLowerCase().includes(this.searchText.toLowerCase()));
  }


  async ngOnInit(): Promise<void> {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    console.log('AttenderChatAllVendorsComponent initialized', this.data);
    this.allVendors = await this.chatService.getChannelsByRfqId(this.data.rfqId, false);
    console.log('All vendors', this.allVendors);

    this.activeSellers = this.allVendors.filter(vendor => !vendor.data?.frozen);
    this.archivedSellers = this.allVendors.filter(vendor => vendor.data?.frozen);
  }

  startChat(seller: any) {
    // Implement your chat start logic
    this.nzDrawerRef.close(seller);
  }

}
