const data = [
  {
    key: "dashboard",
    title: "Dashboard",
    route: "/panel/dashboard",
    icon: "appstore",
  },
  {
    key: "newjob",
    title: "Order Service",
    route: "/panel/new-job",
    icon: "plus",
    info: "Order Service",
  },
  {
    key: "reporting",
    title: "Analytics",
    route: "/panel/reports",
    icon: "area-chart",
    info: "Reports",
  },
  {
    key: "chat",
    title: "Chat",
    route: "/panel/chat",
    icon: "message",
    info: "Chat",

  },
  // {
  //   key: "invitedVendors",
  //   title: "Invited Vendors",
  //   route: "/panel/request/invitedRequest",
  //   icon: "user",
  //   info: "Invited Vendors",
  // },


  /* {
    key: "join-requests",
    title: "Pending User Requests",
    route: "/panel/request/join-requests",
    icon: "user",
    info: "Pending User Requests",
  }, */

  {
    key: "join-requests",
    title: "Teams Members",
    route: "/corporate/team-members",
    icon: "user",
    info: "Teams Members",
  },

  {
    key: "corporateProfile",
    title: "Corporate Profile",
    route: "/corporate/profile",
    icon: "user",
    info: "Corporate Profile",
  },
  {
    key: "paymentHistory",
    title: "Billing",
    route: "/panel/payments",
    icon: "dollar-circle",
    info: "Billing",
  },
  {
    key: "terms-library",
    title: "Terms Library",
    route: "/panel/terms-library",
    icon: "form",
    info: "Terms Library",
  }

];

export { data };
