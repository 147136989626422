import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { RequesterService } from '../../services/requester.service';
import { AuthService } from '../../services/auth.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import jsPDF from "jspdf";
@Component({
  selector: 'app-terms-condition-modal',
  templateUrl: './terms-condition-modal.component.html',
  styleUrl: './terms-condition-modal.component.scss'
})
export class TermsConditionModalComponent {
  @Input() data: any;
  checkStatus: boolean = false;
  @ViewChild("pdfElement", { static: false }) pdfElement!: ElementRef;

  constructor(
    private requesterService: RequesterService,
    private authService: AuthService,
    private modalRef: NzModalRef,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  downloadAsPDF() {
    console.log("Download as PDF");
    const doc = new jsPDF("p", "pt", "a4");
    doc.addFont("ArialMS", "Arial", "normal");
    doc.setFont("Arial");

    const specialElementHandlers = {
      "#editor": function (element, renderer) {
        return true;
      },
    };

    console.log(this.pdfElement);
    const pdfElement = this.pdfElement.nativeElement;

    doc.fromHTML(pdfElement.innerHTML, 15, 15, {
      width: 540,
      // 'elementHandlers': specialElementHandlers
    });

    doc.save("Attender Owner Membership Agreement.pdf");
  }
  // Method to accept terms and conditions
  acceptTermsAndCondition(): void {

    this.requesterService
      .request("post", `users/owners/accept-terms`, {})
      .subscribe(() => {
        this.modalRef.close(true);
      });
  }

  // Method to reject terms and conditions
  rejectTermsAndCondition(): void {
    console.log("Terms Rejected");

    this.authService.logout().then((status) => {

      this.router.navigateByUrl("/auth/login");
      this.modalRef.close(false);

    });

  }
}
