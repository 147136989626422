import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, first, take, takeWhile } from "rxjs/operators";
import { AuthService } from "./common/services/auth.service";
import { IdleUserDetectorService } from "./common/services/idle-user-detector.service";
import { SocketService } from "./common/services/socket.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { ChangePasswordComponent } from "./auth/change-password/change-password.component";
import { RequesterService } from "./common/services/requester.service";
import { TermsConditionModalComponent } from "./common/shared-module/terms-condition-modal/terms-condition-modal.component";
import { AddCardComponent } from "./main/billing/add-card/add-card.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  url: string;
  myProfileData: any;
  initialPasswordChanged: boolean = false;
  ownerTermsAccepted: boolean = false;
  changePasswordModalOpen: boolean = false;
  vendorTermsModalOpen: boolean = false;
  constructor(
    private socketService: SocketService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _idleUserDetectorService: IdleUserDetectorService,
    private _authService: AuthService,
    private modalService: NzModalService,
    private requesterService: RequesterService
  ) {
  }
  ngOnInit() {
    this.socketService.connect();
    this.socketService.authentication();
    this._authService.startIdleTimer();

    this._authService.authState.subscribe((state) => {
      if (state) {
        this.getProfile();
      }

    });
    if (this._authService.isLoggedIn) {
      this.getProfile();
    }
  }

  openModals() {
    if (!this.myProfileData) {
      console.log('Profile data not available');
      return;
    }
    if (!this.ownerTermsAccepted) {
      if (!this.vendorTermsModalOpen) {
        this.vendorTermsModalOpen = true;
        this.termsAndCondition();
      }
    } else if (!this.initialPasswordChanged) {
      if (!this.changePasswordModalOpen) {
        this.changePasswordModalOpen = true;
        this.openChangePasswordModal();
      }
    }
  }

  getProfile() {

    this.requesterService.request('get', 'auth/profile').subscribe(data => {
      // // console.log(data.data)
      this.myProfileData = data.data;
      const { initialPasswordChanged, ownerTermsAccepted } = this.myProfileData;
      this.initialPasswordChanged = initialPasswordChanged;
      this.ownerTermsAccepted = ownerTermsAccepted;
      this.openModals();
    });
  }

  openChangePasswordModal() {
    const modalRef = this.modalService.create({
      nzTitle: 'Set Password',
      nzContent: ChangePasswordComponent,
      nzData: {
        init: true
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: null
    });

    modalRef.afterClose.subscribe(result => {
      this.changePasswordModalOpen = false;
      this.getProfile();
      this.getMyPaymentMethods();

    });
  }

  getMyPaymentMethods() {
    this.requesterService
      .request("get", "payments/stripe/buyer/payment-methods")
      .subscribe((res: any) => {
        if (res.data.length === 0) {
          this.addCard();
        }
      });
  }


  addCard() {
    const modal = this.modalService.create({
      nzTitle: "Add Card",
      nzContent: AddCardComponent,
      nzFooter: null,
      nzClosable: true,
      nzMaskClosable: false,
      nzBodyStyle: {
        padding: "0",
      },
      nzNoAnimation: true,
    });
    modal.afterClose.subscribe((res: any) => {
      this.router.navigate(['/corporate/profile']);
    });
  }


  termsAndCondition() {
    const modal = this.modalService.create({
      nzTitle: "Terms and Conditions",
      nzContent: TermsConditionModalComponent,
      nzWidth: "980px",
      nzStyle: { top: "20px" },
      nzMaskClosable: false,
      nzData: { data: this.myProfileData.company },
      nzClosable: false,
      nzFooter: null,

    });
    // Return a result when closed
    modal.afterClose.subscribe((result) => {
      this.vendorTermsModalOpen = false;
      if (result) {
        this.getProfile();
      }

    });
  }


  // openChangePasswordModal() {
  //   const modalRef = this.modalService.create({
  //     nzTitle: 'Set Password',
  //     nzContent: ChangePasswordComponent,
  //     nzData: {
  //       init: true
  //     },
  //     nzMaskClosable: false,
  //     nzClosable: false,
  //     nzFooter: null
  //   });

  //   modalRef.afterClose.subscribe(result => {
  //     this.router.navigate([], { queryParams: { changePassword: null }, queryParamsHandling: 'merge' });
  //   });
  // }

  ngOnDestroy() {
    this._idleUserDetectorService.stop();
  }
}
