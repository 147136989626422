// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.seller-list-container {
  padding: 16px;
}
.seller-list-container .list-section {
  margin-bottom: 16px;
}
.seller-list-container .list-section .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.seller-list-container .list-section .section-header h4 {
  margin: 0;
}
.seller-list-container .list-section .section-header .search-input {
  width: 200px;
}
.seller-list-container .seller-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}
.seller-list-container .seller-item .seller-info {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
}
.seller-list-container .seller-item .seller-info .seller-details h5 {
  margin: 0;
  margin-bottom: 4px;
}
.seller-list-container .seller-item .seller-info .seller-details .seller-meta {
  display: flex;
  align-items: center;
  gap: 8px;
}
.seller-list-container .seller-item .seller-info .seller-details .seller-meta .meta-icon {
  margin-right: 4px;
}
.seller-list-container .archived-section {
  background-color: #fafafa;
}
.seller-list-container .archived-section .archived-avatar {
  opacity: 0.7;
}
.seller-list-container .archived-section .archived-text {
  color: rgba(0, 0, 0, 0.45);
}
.seller-list-container .archived-list.hidden {
  display: none;
}
.seller-list-container ::ng-deep .ant-list-item {
  padding: 12px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/main/chat/attender-chat-all-vendors/attender-chat-all-vendors.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,mBAAA;AACJ;AACI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACN;AACM;EACE,SAAA;AACR;AAEM;EACE,YAAA;AAAR;AAKE;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,cAAA;AAHJ;AAKI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,OAAA;AAHN;AAMQ;EACE,SAAA;EACA,kBAAA;AAJV;AAOQ;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AALV;AAOU;EACE,iBAAA;AALZ;AAYE;EACE,yBAAA;AAVJ;AAYI;EACE,YAAA;AAVN;AAaI;EACE,0BAAA;AAXN;AAgBI;EACE,aAAA;AAdN;AAmBI;EACE,wBAAA;AAjBN","sourcesContent":[".seller-list-container {\n  padding: 16px;\n\n  .list-section {\n    margin-bottom: 16px;\n\n    .section-header {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      margin-bottom: 16px;\n\n      h4 {\n        margin: 0;\n      }\n\n      .search-input {\n        width: 200px;\n      }\n    }\n  }\n\n  .seller-item {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 8px 0;\n\n    .seller-info {\n      display: flex;\n      align-items: center;\n      gap: 12px;\n      flex: 1;\n\n      .seller-details {\n        h5 {\n          margin: 0;\n          margin-bottom: 4px;\n        }\n\n        .seller-meta {\n          display: flex;\n          align-items: center;\n          gap: 8px;\n\n          .meta-icon {\n            margin-right: 4px;\n          }\n        }\n      }\n    }\n  }\n\n  .archived-section {\n    background-color: #fafafa;\n\n    .archived-avatar {\n      opacity: 0.7;\n    }\n\n    .archived-text {\n      color: rgba(0, 0, 0, 0.45);\n    }\n  }\n\n  .archived-list {\n    &.hidden {\n      display: none;\n    }\n  }\n\n  ::ng-deep {\n    .ant-list-item {\n      padding: 12px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
