import { Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NzMessageService } from "ng-zorro-antd/message";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { ConfigurationService } from "src/app/common/services/configuration.service";
import { RequesterService } from "src/app/common/services/requester.service";

@Component({
  selector: "app-add-edit-terms-modal",
  templateUrl: "./add-edit-terms-modal.component.html",
  styleUrl: "./add-edit-terms-modal.component.scss",
})
export class AddEditTermsModalComponent {
  readonly nzModalData = inject(NZ_MODAL_DATA);
  termData: any;
  termsForm!: FormGroup;
  isLoading = false;
  fileList: any[] = [];

  configuration = ConfigurationService;
  mediaUploadPath = this.configuration.apiBaseUrl + "/api/media";
  mediaBasePath = ConfigurationService.mediaBaseUrl;
  // mediaLoading: boolean = false;
  limitOfFileUpload: number = 5;

  customRequestHeaders = () => {
    return { Authorization: `Bearer ${localStorage.getItem("bearerToken")}` };
  };

  constructor(
    private fb: FormBuilder,
    private message: NzMessageService,
    private requestService: RequesterService,
    private nzModalRef: NzModalRef
  ) {
    this.termData = this.nzModalData.data;
    console.log("Terms id", this.termData, this.nzModalData);
  }

  ngOnInit(): void {
    this.initForm();
  }

  /*  ngOnChanges(): void {
     if (this.termData && this.termsForm) {
       this.populateForm();
     }
   } */

  initForm(): void {
    this.termsForm = this.fb.group({
      title: ["", [Validators.required]],
      content: [""],
      files: [null],
    });

    if (this.termData) {
      this.populateForm();
      this.fileList = this.termData?.files || [];
    }
  }

  populateForm(): void {
    this.termsForm.patchValue({
      title: this.termData?.title || "",
      content: this.termData?.content || "",
      files: this.termData?.files || null,
    });
  }

  handleUploadChange(info: any): void {
    console.log("handle upload change", info);
    if (info.file.status === "done") {
      this.message.success(`${info.file.name} file uploaded successfully`);
      this.fileList.push({
        name: info?.file?.response?.data?.originalname,
        path: info?.file?.response?.data?.path,
        _id: info?.file?.response?.data?._id,
      });
      console.log("LIST", this.fileList);

      if (this.fileList.length) {
        this.termsForm.patchValue({
          files: this.fileList,
        });
      } else {
        this.termsForm.patchValue({
          files: null,
        });
      }
    } else if (info.file.status === "error") {
      this.message.error(`${info.file.name} file upload failed.`);
    }
  }

  removeFile = (file: any): void => {
    console.log('GET FILE', file);
    let fileId: string = this.getFileId(file);
   /*  let fileId: string = "";
    if (this.termData && this.termData._id) {
      fileId = file._id;
      console.log("file id at update", fileId, file);
    } else {
      fileId = file.response.data._id;
      console.log("file id at add", fileId, file);
    } */
   

    if (fileId) {
      this.requestService.request("delete", `media/${fileId}`).subscribe({
        next: (data: any) => {
          this.fileList = this.fileList.filter((f) => f._id !== fileId);

          if (this.fileList.length) {
            this.termsForm.patchValue({
              files: this.fileList,
            });
          } else {
            this.termsForm.patchValue({
              files: null,
            });
          }
        },
        error: (err: any) => {
          this.message.error("Something went wrong");
          console.log(err);
        },
      });
    }
  };

  private getFileId(file: any): string {
    // Handle different file object structures
    if (file.response?.data?._id) {
      // Case 1: Newly uploaded file
      return file.response.data._id;
    } else if (file._id) {
      // Case 2: Existing file
      return file._id;
    }
    return '';
  }

  submitForm(): void {
    console.log("Terms Form :", this.termsForm.value);
    console.log("On submit Terms Form :", this.termData);
    if (this.termsForm.valid) {
      this.isLoading = true;
      const formData = this.termsForm.value;
      if (this.termData && this.termData._id) {
        this.requestService
          .request("put", `terms/${this.termData._id}`, formData)
          .subscribe({
            next: (data: any) => {
              this.isLoading = false;
              this.message.success("Terms updated successfully");
              this.resetForm();
              this.nzModalRef.close(true);
            },
            error: (err: any) => {
              this.isLoading = false;
              this.message.error("Something went wrong");
            },
          });
      } else {
        this.requestService.request("post", "terms", formData).subscribe({
          next: (data: any) => {
            this.isLoading = false;
            this.message.success("Terms added successfully");
            this.resetForm();
            this.nzModalRef.close(true);
          },
          error: (err: any) => {
            this.isLoading = false;
            this.message.error("Something went wrong");
          },
        });
      }
    } else {
      Object.values(this.termsForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsTouched();
        }
      });
    }
  }

  resetForm(): void {
    this.termsForm.reset();
    this.fileList = [];
  }
}
