import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, fromEvent, debounceTime, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  private isMobileSubject: BehaviorSubject<boolean>;
  private isSmallScreenSubject: BehaviorSubject<boolean>;
  private isMediumToSmallScreen: BehaviorSubject<boolean>;


  public isMobile$: Observable<boolean>;
  public isSmallScreen$: Observable<boolean>;
  public isMediumToSmallScreen$: Observable<boolean>;

  constructor() {
    this.isMobileSubject = new BehaviorSubject<boolean>(this.checkIsMobile());
    this.isSmallScreenSubject = new BehaviorSubject<boolean>(this.checkIsSmallScreen());
    this.isMediumToSmallScreen = new BehaviorSubject<boolean>(this.checkIsMediumScreen());

    this.isMobile$ = this.isMobileSubject.asObservable();
    this.isSmallScreen$ = this.isSmallScreenSubject.asObservable();
    this.isMediumToSmallScreen$ = this.isMediumToSmallScreen.asObservable();
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.isMobileSubject.next(this.checkIsMobile());
        this.isSmallScreenSubject.next(this.checkIsSmallScreen());
      });
  }

  private checkIsMobile(): boolean {
    return window.innerWidth < 768;
  };

  //use for ipad mini and below screen size
  private checkIsSmallScreen(): boolean {
    return window.innerWidth <= 768;
  }
  //use for 820px and smaller screen size
  private checkIsMediumScreen(): boolean {
    return window.innerWidth <= 820;
  }

  ngOnDestroy() {
    this.isMobileSubject.complete();
    this.isSmallScreenSubject.complete();
  }
}
