export interface UserModel {
  userId: string;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  userType: string;
  profileImage: string;
  defaultCompanyId: number;
  profileComplete: boolean;
  superAdmin: boolean;
  admin: boolean;
}

export class User {
  
  constructor(user: any) {
    Object.keys(user).forEach((key) => {
      this[key] = user[key];
    });
  }
}

export interface UpdateUser  {
  _id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  mobile: string;
  fax: string;
  designation: string;
  emailVerified: boolean;
  subRole: string;
  role: string;
  initialPasswordChanged: boolean;
  additionalRoles: string[];
  company: string;
  admin: boolean;
  superAdmin: boolean;
  companyName: string;
  adminGroups: any[];
  status: boolean;
  rfqs: any[];
  paymentEnabled: boolean;
  vendorTermsAccepted: boolean;
  isUpdating: boolean;
}
