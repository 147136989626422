import { Injectable } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { RequesterService } from './requester.service';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripe: any;

  constructor(private requesterService: RequesterService) { }

  async getStripe() {
    if (!this.stripe) {
      this.stripe = await loadStripe('pk_test_51P1MKNItVaMpopZmHla746N0ucrrk8YSn49WG2dNjlWPbZJaFsr2P5VDaygWaso8h50CWLo6mW3DIXmB7k7Jx3MT00966Xhhyq');
    }
    return this.stripe;
  }

  addCard(tokenId: string) {
    return this.requesterService.request('post', 'payments/stripe/buyer/payment-methods', { token: tokenId }).toPromise();
  }
}
