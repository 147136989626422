// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-person {
  display: flex;
  flex-direction: column;
}

.certain-search-item-count {
  position: absolute;
  color: #999;
  right: 16px;
}

.more-link {
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/no-sidebar/header/search-modal/search-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;AACA;;AAEA;EACA,YAAA;AACA","sourcesContent":[".contact-person{\n  display: flex;\n  flex-direction: column;\n}\n\n.certain-search-item-count {\nposition: absolute;\ncolor: #999;\nright: 16px;\n}\n\n.more-link {\nfloat: right;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
