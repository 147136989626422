// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[nz-button] {
  margin-right: 8px;
  margin-bottom: 12px;
}

.scroll-container {
  height: 300px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.outer_scroll_container {
  margin-top: 20px;
}

.scroll-content p {
  margin-bottom: 12px;
}

.scroll-content label {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/common/shared-module/terms-condition-modal/terms-condition-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,gBAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;AACF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,cAAA;AAAF","sourcesContent":["[nz-button] {\n  margin-right: 8px;\n  margin-bottom: 12px;\n}\n\n.scroll-container {\n  height: 300px;\n  overflow-y: auto;\n  padding: 10px;\n  border: 1px solid #d9d9d9;\n  border-radius: 4px;\n  background-color: #f9f9f9;\n}\n\n\n.outer_scroll_container {\n  margin-top: 20px;\n}\n\n.scroll-content p {\n  margin-bottom: 12px;\n}\n\n.scroll-content label {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
