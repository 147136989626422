import { ChangeDetectorRef, Component } from "@angular/core";
import { NzModalRef } from "ng-zorro-antd/modal";
import { debounceTime, distinctUntilChanged, Subject } from "rxjs";
import { Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { RequesterService } from "src/app/common/services/requester.service";
import { SharedModuleModule } from "src/app/common/shared-module/shared-module.module";

interface APIResponse {
  error: boolean;
  statusCode: number;
  success: boolean;
  data: any;
  totalCount?: number;
}

@Component({
  selector: "app-search-modal",
  standalone: true,
  // imports: [BasicModule,NzAutocompleteModule,NzDividerModule, NzRateModule, NzSpinModule, NzEmptyModule],
  imports: [SharedModuleModule],
  templateUrl: "./search-modal.component.html",
  styleUrl: "./search-modal.component.scss",
})
export class SearchModalComponent {
  inputValue: string = "";
  options: string[] = [];
  optionGroups: any[] = [];
  searchText$: Subject<string> = new Subject<string>();
  isLoading = false;
  recentSearchedData: any;
  matchingCount: number = 0;
  constructor(
    private modalRef: NzModalRef,
    // private apiService: ApiService,
    private requestService: RequesterService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private message: NzMessageService
  ) {
    this.searchText$
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        console.log(value);
        if (!value) {
          this.initSearch();
        } else {
          this.storeRecentSearches(value);
          this.getSearchedData();
        }
      });
  }
  onInputClose(): void {
    if (this.inputValue !== "") {
      this.inputValue = "";
    } else {
      this.modalRef.destroy(true);
    }
  }

  ngOnInit(): void {
    this.initSearch();
  }

  initSearch(): void {
    this.recentSearchedData = {
      title: "Recent Searches",
      children: this.recentSearches.map((item: string) => {
        return { title: item, type: "recent" };
      }),
    };
    this.optionGroups = [
      // this.recentSearchedData
    ];
  }

  get recentSearches(): string[] {
    // Get them from local storage
    const recentSearches: string[] = [];
    if (localStorage.getItem("recentSearches")) {
      return JSON.parse(localStorage.getItem("recentSearches") || "");
    }
    return recentSearches;
  }

  storeRecentSearches(text: string): void {
    if (!text) {
      return;
    }
    // Store them in local storage
    const existingRecentSearches: string[] = this.recentSearches;
    existingRecentSearches.unshift(text);
    const uniqueRecentSearches: string[] = Array.from(
      new Set(existingRecentSearches)
    );
    // Store only 5 recent searches
    const recentSearches: string[] = uniqueRecentSearches.slice(0, 5);
    localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
  }

  clearRecentSearches(): void {
    localStorage.removeItem("recentSearches");
    this.initSearch();
  }

  getSearchedData(): void {
    this.isLoading = true;
    const params: any = {};
    if (this.inputValue) {
      params.q = this.inputValue.trim();
    }
    this.requestService
      .request("get", "generals/global-search", params)
      .subscribe(
        (res: APIResponse) => {
          this.optionGroups = [
            {
              title: "Vendors",
              icon: "user",
              children: res.data.hits.map((item: any) => {
                return {
                  title: item.companyName,
                  rating: item.ratings,
                  type: "vendor",
                  id: item.id,
                };
              }),
            },
          ];
          this.matchingCount = res.data.estimatedTotalHits;
          this.cdr.detectChanges();
          this.isLoading = false;
        },
        (err) => {
          console.log(err.error);
          this.message.error(err.error.message);
          this.isLoading = false;
        }
      );
  }

  openResource(event: any, item: any): void {
    if (item.type === "vendor") {
      event.preventDefault();
      console.log("Opening the resource");
      this.router.navigate([
        "/main/vendors/manage-vendors",
        item.id,
        "details",
      ]);
      // Open the resource
      this.modalRef.destroy(true);
    } else if (item.type === "recent") {
      // this.inputValue = item.title;
      // this.getSearchedData();
      event.preventDefault();
      this.searchText$.next(item.title + " ");
    }
  }
}
