// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-list {
  height: 100%;
}
.chat-list .chat-list-card {
  height: 100%;
}
.chat-list .chat-list-card ::ng-deep .ant-card-body {
  height: calc(100% - 55px);
  overflow-y: auto;
}
.chat-list .chat-item-card {
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 0.3s;
}
.chat-list .chat-item-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}
.chat-list .chat-item-card.active-chat {
  background: #e6f7ff;
  border-color: #91d5ff;
}
.chat-list .chat-item-card.archived {
  background: #f5f5f5;
  border-color: #e8e8e8;
  opacity: 0.65;
  cursor: not-allowed;
}
.chat-list .chat-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.chat-list .chat-item .chat-item-avatar {
  flex-shrink: 0;
}
.chat-list .chat-item .chat-item-content {
  flex: 1;
  min-width: 0;
}
.chat-list .chat-item .chat-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.chat-list .chat-item .chat-item-header h4 {
  margin: 0;
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-list .chat-item .chat-item-message {
  color: #8c8c8c;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/main/chat/attender-chat-list/attender-chat-list.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,YAAA;AACJ;AACI;EACE,yBAAA;EACA,gBAAA;AACN;AAGE;EACE,kBAAA;EACA,eAAA;EACA,oBAAA;AADJ;AAGI;EACE,yCAAA;AADN;AAII;EACE,mBAAA;EACA,qBAAA;AAFN;AAII;EACE,mBAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;AAFN;AAME;EACE,aAAA;EACA,uBAAA;EACA,SAAA;AAJJ;AAMI;EACE,cAAA;AAJN;AAOI;EACE,OAAA;EACA,YAAA;AALN;AAQI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AANN;AAQM;EACE,SAAA;EACA,OAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AANR;AAUI;EACE,cAAA;EACA,eAAA;AARN","sourcesContent":[".chat-list {\n  height: 100%;\n\n  .chat-list-card {\n    height: 100%;\n\n    ::ng-deep .ant-card-body {\n      height: calc(100% - 55px);\n      overflow-y: auto;\n    }\n  }\n\n  .chat-item-card {\n    margin-bottom: 8px;\n    cursor: pointer;\n    transition: all 0.3s;\n\n    &:hover {\n      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);\n    }\n\n    &.active-chat {\n      background: #e6f7ff;\n      border-color: #91d5ff;\n    }\n    &.archived {\n      background: #f5f5f5;\n      border-color: #e8e8e8;\n      opacity: 0.65;\n      cursor: not-allowed;\n    }\n  }\n\n  .chat-item {\n    display: flex;\n    align-items: flex-start;\n    gap: 12px;\n\n    .chat-item-avatar {\n      flex-shrink: 0;\n    }\n\n    .chat-item-content {\n      flex: 1;\n      min-width: 0;\n    }\n\n    .chat-item-header {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      margin-bottom: 4px;\n\n      h4 {\n        margin: 0;\n        flex: 1;\n        min-width: 0;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        white-space: nowrap;\n      }\n    }\n\n    .chat-item-message {\n      color: #8c8c8c;\n      font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
