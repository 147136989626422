import { Component, EventEmitter, Input, Output } from '@angular/core';
import { JobGroup, VendorChannel } from './chat-groups.model';
import { RequesterService } from 'src/app/common/services/requester.service';
import { debounceTime, skip, Subject } from 'rxjs';
import { ChatService } from 'src/app/common/services/chat.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { AttenderChatAllVendorsComponent } from '../attender-chat-all-vendors/attender-chat-all-vendors.component';

@Component({
  selector: 'app-attender-chat-group-list',
  templateUrl: './attender-chat-group-list.component.html',
  styleUrl: './attender-chat-group-list.component.scss'
})
export class AttenderChatGroupListComponent {
  @Input() jobGroups: any[] = [];
  @Input() selectedChannel: string | null = null;
  @Output() channelSelect = new EventEmitter<VendorChannel>();
  allJobs: any[] = [];
  searchText: string = '';
  searchText$ = new Subject<string>();
  page: number = 1;
  pageSize: number = 5;
  isLoading: boolean = false;
  hasMore: boolean = true;
  constructor(
    private requesterService: RequesterService,
    private chatService: ChatService,
    private nzDrawerService: NzDrawerService
  ) {

  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.chatService.isConnected$.subscribe(isConnected => {

      this.getChatJobs();
    } );
    this.searchText$.pipe(debounceTime(500)).subscribe(searchText => {
      this.getChatJobs();
    } );

    this.chatService.chatActivity$.subscribe((e: any) => {
      console.log('Chat activity detected', e);
      const channelId = e.channel_id;
      const jobId = channelId.split('_')[1];
      this.rearrangeJobs(jobId);
    } );
  }

  // on selectedChannel change
  ngOnChanges(): void {
    if(this.selectedChannel) {
      console.log('Selected channel changed', this.selectedChannel);
      const jobId = this.selectedChannel.split('_')[1];
      this.rearrangeJobs(jobId);
    }
  }

  rearrangeJobs(jobId: string): void {
    // Check if the job is already in the list
    const jobIndex = this.allJobs.findIndex(job => job._id === jobId);
    if(jobIndex > -1) {
      const job = this.allJobs[jobIndex];
      this.chatService.getChannelsByRfqId(jobId).then(channels => {
        job.channels = channels;
        this.allJobs.splice(jobIndex, 1);
        this.allJobs = [job, ...this.allJobs];

      } );

    } else {
      // get the job details and add it to the list
      this.requesterService.request('get', `jobs/rfqs/jobs-for-chat-owner`, {_id: jobId}).subscribe(data => {
        if(data.data.length > 0) {
          data.data[0].channels = [];
          this.chatService.getChannelsByRfqId(jobId).then(channels => {
            data.data[0].channels = channels;
            this.allJobs = [data.data[0], ...this.allJobs];
          } );
        }
      } );
    }
  }

  // Sample data for demonstration
  sampleJobGroups: JobGroup[] = [
    {
      id: 'job1',
      job_name: 'Website Development Project',
      status: 'active',
      created_at: new Date(),
      vendor_channels: [
        {
          id: 'channel1',
          vendor_name: 'TechCorp Solutions',
          unread_count: 3,
          last_message_at: new Date(),
          frozen: false
        },
        {
          id: 'channel2',
          vendor_name: 'Digital Innovators',
          unread_count: 1,
          last_message_at: new Date(),
          frozen: false
        }
      ]
    },
    {
      id: 'job2',
      job_name: 'Mobile App Development',
      status: 'completed',
      created_at: new Date(),
      vendor_channels: [
        {
          id: 'channel3',
          vendor_name: 'AppMasters Inc',
          unread_count: 0,
          last_message_at: new Date(),
          frozen: true
        },
        {
          id: 'channel4',
          vendor_name: 'Mobile Experts',
          unread_count: 2,
          last_message_at: new Date(),
          frozen: true
        }
      ]
    }
  ];

  getJobTotalUnread(job: any): number {
    return job.channels && job.channels.length && job.channels.reduce((sum, channel) => sum + channel.state.unreadCount, 0);
  }

  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getChatJobs(loadMore = false): void {
    if(loadMore) {
      this.page++;
    }
    this.isLoading = true;
    const params: any = {
      limit: this.pageSize,
      skip: (this.page - 1) * this.pageSize
    };
    if(this.searchText) {
      params.search = this.searchText;
    }
    this.requesterService.request('get', 'jobs/rfqs/jobs-for-chat-owner', params).subscribe(async data => {
      if(data.data.length < this.pageSize) {
        this.hasMore = false;
      }
      console.log(data);
      data.data.forEach((job: any) => {
        job.channels = [];
      } );
      if(loadMore) {
        this.allJobs = this.allJobs.concat(data.data);
      } else {
        this.allJobs = data.data;
      }
      this.isLoading = false;
      for (const element of this.allJobs) {
        if(!element.channels || element.channels.length === 0) {
          element.channels = await this.chatService.getChannelsByRfqId(element._id);
          await this.delay(500);
        }
      }
      // console.log(this.allJobs);

    } );
  }

  selectChannel(channel: VendorChannel): void {
    if (!channel.frozen) {
      this.channelSelect.emit(channel);
      this.selectedChannel = channel.id;
    }
  }

  onAllVendors(rfqId: string): void {
    const drawer = this.nzDrawerService.create({
      nzTitle: 'All Sellers',
      nzContent: AttenderChatAllVendorsComponent,
      nzWidth: 600,
      nzBodyStyle: { padding: '0' },
      nzData: {
        rfqId
      }
    });

    drawer.afterClose.subscribe((data: any) => {
      if(data) {
        this.selectChannel(data);
      }
    } );
  }
}
