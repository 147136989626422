import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { AuthService } from 'src/app/common/services/auth.service';
import { StripeService } from 'src/app/common/services/stripe.service';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrl: './add-card.component.scss'
})
export class AddCardComponent implements OnInit {
  @ViewChild('cardNumber') cardNumberElement?: ElementRef;
  @ViewChild('cardExpiry') cardExpiryElement?: ElementRef;
  @ViewChild('cardCvc') cardCvcElement?: ElementRef;

  cardForm: FormGroup;
  stripe: any;
  cardNumber: any;
  cardExpiry: any;
  cardCvc: any;
  isLoading = false;
  cardErrors: { [key: string]: string } = {};

  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private message: NzMessageService,
    private nzModalRef: NzModalRef,
    private authService: AuthService
  ) {
    this.cardForm = this.fb.group({});
  }

  ngOnInit(): void {
    // Only initialize stripe instance here
    this.initStripe();
  }

  ngAfterViewInit(): void {
    // Small delay to ensure modal transition is complete
    setTimeout(() => {
      this.initializeStripeElements();
    }, 100);
  }

  private async initStripe() {
    this.stripe = await this.stripeService.getStripe();
  }

  private initializeStripeElements() {
    const elements = this.stripe.elements();

    const style = {
      base: {
        color: '#32325d',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#ff5757',
        iconColor: '#ff5757'
      }
    };

    this.cardNumber = elements.create('cardNumber', { style });
    this.cardExpiry = elements.create('cardExpiry', { style });
    this.cardCvc = elements.create('cardCvc', { style });

    // Mount elements
    if (this.cardNumberElement) this.cardNumber.mount(this.cardNumberElement.nativeElement);
    if (this.cardExpiryElement) this.cardExpiry.mount(this.cardExpiryElement.nativeElement);
    if (this.cardCvcElement) this.cardCvc.mount(this.cardCvcElement.nativeElement);

    // Add event listeners
    this.cardNumber.addEventListener('change', (event: any) => this.handleChange(event, 'cardNumber'));
    this.cardExpiry.addEventListener('change', (event: any) => this.handleChange(event, 'cardExpiry'));
    this.cardCvc.addEventListener('change', (event: any) => this.handleChange(event, 'cardCvc'));
  }

  private mountElements() {
    if (this.cardNumberElement) {
      this.cardNumber.mount(this.cardNumberElement.nativeElement);
    }
    if (this.cardExpiryElement) {
      this.cardExpiry.mount(this.cardExpiryElement.nativeElement);
    }
    if (this.cardCvcElement) {
      this.cardCvc.mount(this.cardCvcElement.nativeElement);
    }
  }

  private setupEventListeners() {
    this.cardNumber.addEventListener('change', (event: any) => this.handleChange(event, 'cardNumber'));
    this.cardExpiry.addEventListener('change', (event: any) => this.handleChange(event, 'cardExpiry'));
    this.cardCvc.addEventListener('change', (event: any) => this.handleChange(event, 'cardCvc'));
  }

  handleChange(event: any, fieldName: string) {
    if (event.error) {
      this.cardErrors[fieldName] = event.error.message;
    } else {
      delete this.cardErrors[fieldName];
    }
  }

  async onSubmit() {
    this.isLoading = true;

    try {
      const { token, error } = await this.stripe.createToken(this.cardNumber);

      if (error) {
        throw new Error(error.message);
      }

      const result = await this.stripeService.addCard(token.id);
      this.message.success('Card added successfully');
      this.authService.updateBillingManagerState.next(true);
      this.nzModalRef.close(true);
    } catch (error: any) {
      this.message.error(error.message);
    } finally {
      this.isLoading = false;
    }
  }
}
