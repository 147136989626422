import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatRoutingModule } from './chat-routing.module';
import { SharedModuleModule } from '../../common/shared-module/shared-module.module';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AttenderMessagingComponent } from './attender-messaging/attender-messaging.component';
import { AttenderChatListComponent } from './attender-chat-list/attender-chat-list.component';
import { AttenderChatWindowComponent } from './attender-chat-window/attender-chat-window.component';
import { AttenderChatContainerComponent } from './attender-chat-container/attender-chat-container.component';
import { AttenderChatGroupListComponent } from './attender-chat-group-list/attender-chat-group-list.component';
import { AttenderChatAllVendorsComponent } from './attender-chat-all-vendors/attender-chat-all-vendors.component';


@NgModule({
  declarations: [
    AttenderMessagingComponent,
    AttenderChatListComponent,
    AttenderChatWindowComponent,
    AttenderChatContainerComponent,
    AttenderChatGroupListComponent,
    AttenderChatAllVendorsComponent
  ],
  exports: [
  ],
  imports: [
    CommonModule,
    ChatRoutingModule,
    SharedModuleModule,
    DragDropModule
  ]
})
export class ChatModule { }
