import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoSidebarComponent } from './layouts/no-sidebar/no-sidebar.component';
import { DefaultComponent } from './layouts/default/default.component';
import { NotFoundComponent } from './misc/not-found/not-found.component';
// import { NoAuthGuard } from './common/guards/no-auth.guard';
import { AuthGuard } from './common/guards/auth.guard';
import {HomeComponent} from './misc/frontend/home/home.component';
import {NoAuthGuard} from './common/guards/no-auth.guard'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'panel'
  },
  {
    path: 'auth',
    component: NoSidebarComponent,
    // canActivate: [NoAuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'noauth',
    component: NoSidebarComponent,
    // canActivate: [NoAuthGuard],
    loadChildren: () => import('./noauth/noauth.module').then(m => m.NoauthModule)
  },
 /*  {
    path: 'panel',
    component: DefaultComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./main/main.module').then(m => m.MainModule)
  },
  {
    path: 'corporate',
    component: DefaultComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./corporate/corporate.module').then(m => m.CorporateModule)
  }, */
  {
    path: '',
    component: DefaultComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'panel',
        loadChildren: () => import('./main/main.module').then(m => m.MainModule)
      },
      {
        path: 'corporate',
        loadChildren: () => import('./corporate/corporate.module').then(m => m.CorporateModule)
      }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
