// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messaging-container {
  min-height: 60vh;
}
.messaging-container .messaging-card {
  height: calc(100vh - 200px);
}
.messaging-container .messaging-card ::ng-deep .ant-card-body {
  height: 100%;
  padding: 0;
}
.messaging-container .messaging-card ::ng-deep .ant-card-body .ant-row {
  height: 100%;
}
.messaging-container .chat-list-col {
  height: 100%;
  border-right: 1px solid #f0f0f0;
  padding-bottom: 24px;
}
.messaging-container .chat-window-col {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/main/chat/attender-messaging/attender-messaging.component.scss"],"names":[],"mappings":"AAAA;EAGE,gBAAA;AADF;AAGE;EACE,2BAAA;AADJ;AAGI;EACE,YAAA;EACA,UAAA;AADN;AAGM;EACE,YAAA;AADR;AAME;EACE,YAAA;EACA,+BAAA;EACA,oBAAA;AAJJ;AAOE;EACE,YAAA;AALJ","sourcesContent":[".messaging-container {\n  // padding: 24px;\n  // background: #f0f2f5;\n  min-height: 60vh;\n\n  .messaging-card {\n    height: calc(100vh - 200px);\n\n    ::ng-deep .ant-card-body {\n      height: 100%;\n      padding: 0;\n\n      .ant-row {\n        height: 100%;\n      }\n    }\n  }\n\n  .chat-list-col {\n    height: 100%;\n    border-right: 1px solid #f0f0f0;\n    padding-bottom: 24px;\n  }\n\n  .chat-window-col {\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
