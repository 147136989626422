import { Injectable } from '@angular/core';
import { User, UserModel } from '../models/user';
import { RequesterService } from './requester.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { IdleUserDetectorService } from './idle-user-detector.service';
import { Router } from '@angular/router';
import { ChatService } from './chat.service';

interface PaymentState {
  planActive: boolean,
  onPaidPlan: boolean
}

interface APIResponse {
  error: boolean;
  statusCode: number;
  success: boolean;
  data: any;
  totalCount?: number;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public authState: Subject<boolean> = new Subject<boolean>();
  localStorageTokenFieldName = 'bearerToken';
  localStorageTokenExpiryFieldName = 'bearerTokenExpiry';
  localStorageUserFieldName = 'userDetails';
  bearerToken = null;
  bearerTokenExpiry = null;
  userDetails: User | null = null;
  idleSubscriptionEstablished = false;

  public userDetailsOfLocalStorage = new BehaviorSubject<User>(null);
  userDetailsOfLocalStorage$ = this.userDetailsOfLocalStorage.asObservable();

  public billingManagerState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public updateBillingManagerState: Subject<any> = new Subject<any>();

  /*   paymentStateSubject = new BehaviorSubject<PaymentState>({ planActive: true, onPaidPlan: true });
    paymentState$ = this.paymentStateSubject.asObservable();
    public updatePaymentStateSubject: Subject<boolean> = new Subject<boolean>();
   */
  constructor(
    private requesterService: RequesterService,
    private idleUserDetectorService: IdleUserDetectorService,
    private router: Router,
    private chatService: ChatService
  ) {
    this.getDataFromLocalStorage();
    this.authState.subscribe((state) => {
      if (state) {
        const userDetails = new User(JSON.parse(localStorage.getItem('userDetails')));
        this.chatService.initializeChat(userDetails, 'owner');
      } else {
        this.chatService.disconnect();
      }

      /*  if (state.isAuthenticated) {
         this.getSubscriptionStatus();
       } */
    });

    /*  this.updatePaymentStateSubject.subscribe(() => {
       this.getSubscriptionStatus();
     } ); */

    // console.log(this.bearerToken, this.bearerTokenExpiry, this.userDetails)
    if (AuthService.masterIsLoggedIn) {
      this.authState.next(true);
    } else {
      this.authState.next(false);
    }

    this.updateBillingManagerState.subscribe((data) => {
      this.getProfile();
    });
    this.getProfile();



  }

  getProfile() {

    this.requesterService.request('get', 'auth/profile').subscribe(data => {
      // // console.log(data.data)
      this.billingManagerState.next(!!data.data.billingManager);
    });
  }

  /*   private getSubscriptionStatus(): void {
      this.requesterService.request('get','payments/stripe/payment-status').subscribe((res: APIResponse) => {
        console.log('Subscription status:', res);
        if(res.data.subscription) {
          const planExpiryDate = new Date(res.data.subscription.endDate);
          const planActive = planExpiryDate > new Date();
          if(planActive) {
            this.paymentStateSubject.next({ planActive, onPaidPlan: true });
          } else {
            this.paymentStateSubject.next({ planActive, onPaidPlan: false });
          }
        } else {
          this.paymentStateSubject.next({ planActive: false, onPaidPlan: false });
        }
      } );
    } */

  //get the profile changes and changes from user management and update the local storage userDetails
  updateUserDetailsInLocalStorage(userDetails: User) {
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
    this.userDetailsOfLocalStorage.next(userDetails);
  }

  public static get masterIsLoggedIn(): boolean {
    let bearerToken = null;
    let bearerTokenExpiry = null;
    let userDetails: User | null = null;
    if (localStorage.getItem('bearerToken')) {
      bearerToken = localStorage.getItem('bearerToken');
      if (localStorage.getItem('bearerTokenExpiry')) {
        bearerTokenExpiry = localStorage.getItem('bearerTokenExpiry');
      }
    }
    if (localStorage.getItem('userDetails')) {
      userDetails = new User(JSON.parse(localStorage.getItem('userDetails')));
    }
    // console.log(bearerToken, bearerTokenExpiry > (new Date()).getTime(),  !!userDetails);
    if (!(bearerToken && bearerTokenExpiry > (new Date()).getTime() && !!userDetails)) {
      AuthService.clearDataFromLocalStorage();
    }
    const app = localStorage.getItem('app');
    if (app !== 'owner') {
      AuthService.clearDataFromLocalStorage();
      return false;
    }
    return !!(bearerToken && bearerTokenExpiry > (new Date()).getTime() && !!userDetails);
  }

  public get isLoggedIn(): boolean {
    return !!(this.bearerToken && this.bearerTokenExpiry > (new Date()).getTime() && !!this.userDetails);
  }

  public static getUserDetails(): User | null {
    if (localStorage.getItem('userDetails')) {
      return new User(JSON.parse(localStorage.getItem('userDetails')));
    }
    return null;
  }

  public static isSuperAdmin(): boolean {
    if (localStorage.getItem('superAdmin')) {
      return JSON.parse(localStorage.getItem('superAdmin'));
    }
    return false;
  }

  public static isAdmin(): boolean {
    if (localStorage.getItem('admin')) {
      return JSON.parse(localStorage.getItem('admin'));
    }
    return false;
  }

  public static clearDataFromLocalStorage() {
    const localStorageTokenFieldName = 'bearerToken';
    const localStorageTokenExpiryFieldName = 'bearerTokenExpiry';
    const localStorageUserFieldName = 'userDetails';
    localStorage.removeItem(localStorageTokenFieldName);
    localStorage.removeItem(localStorageTokenExpiryFieldName);
    localStorage.removeItem(localStorageUserFieldName);
    localStorage.removeItem('superAdmin');
    localStorage.removeItem('admin');
    localStorage.removeItem('app');
    localStorage.clear();
  }

  getDataFromLocalStorage() {
    if (localStorage.getItem(this.localStorageTokenFieldName)) {
      this.bearerToken = localStorage.getItem(this.localStorageTokenFieldName);
      if (localStorage.getItem(this.localStorageTokenExpiryFieldName)) {
        this.bearerTokenExpiry = localStorage.getItem(this.localStorageTokenExpiryFieldName);
      }
    }
    if (localStorage.getItem(this.localStorageUserFieldName)) {
      this.userDetails = new User(JSON.parse(localStorage.getItem(this.localStorageUserFieldName)));
    }
  }

  setDataToLocalStorage(userDetails: any, bearerToken: string, bearerTokenExpiry: string) {
    localStorage.setItem(this.localStorageTokenFieldName, bearerToken);
    localStorage.setItem(this.localStorageTokenExpiryFieldName, (new Date(bearerTokenExpiry)).getTime().toString());
    localStorage.setItem('superAdmin', String(userDetails.superAdmin));
    localStorage.setItem('admin', String(userDetails.superAdmin || !!userDetails.admin));
    this.setUserDataInLocalStorage(userDetails);
    localStorage.setItem('app', 'owner');
  }

  setUserDataInLocalStorage(userDetails: User) {
    localStorage.setItem(this.localStorageUserFieldName, JSON.stringify(new User(userDetails)));
  }

  public login(email: string, password: string): Observable<any> {
    this.startIdleTimer();
    return this.requesterService.request('post', 'auth/login', { email, password }, false)
      .pipe(
        map((apiResponse: any) => {
          console.log(apiResponse);
          if (apiResponse.data && apiResponse.data.user && apiResponse.data.user.role !== 'owner') {
            throw new Error('Invalid Email/Password');
          }
          if (apiResponse.data) {
            console.log(apiResponse.data);
            this.setDataToLocalStorage(apiResponse.data.user, apiResponse.data.token, apiResponse.data.expireAt);
            this.authState.next(true);
          }
          return apiResponse;
        })
      );
  }




  public loginViaToken(token: string): Observable<any> {
    this.startIdleTimer();
    localStorage.clear();
    return this.requesterService.request('post', 'auth/login-via-token', { token }, false)
      .pipe(
        map((apiResponse: any) => {
          if (apiResponse.data) {
            console.log(apiResponse.data);
            this.setDataToLocalStorage(apiResponse.data.user, apiResponse.data.token, apiResponse.data.expireAt);
            localStorage.setItem('quickJobToken', token);
            this.authState.next(true);
          }
          return apiResponse;
        })
      );
  }


  public verifyRegistration(token: string): Observable<any> {
    AuthService.clearDataFromLocalStorage();
    return this.requesterService.request('post', 'auth/verify-email', { token }, false)
      .pipe(
        map((apiResponse: any) => {
          if (apiResponse.data) {
            this.setDataToLocalStorage(apiResponse.data.user, apiResponse.data.token, apiResponse.data.expireAt);
            this.authState.next(true);
          }
          return apiResponse;
        })
      );
  }

  // public logout() {
  //   // API CAll
  //   AuthService.clearDataFromLocalStorage();
  //   this.authState.next(false);
  // }

  public logout(): Promise<any> {
    this.idleSubscriptionEstablished = false;
    this.idleUserDetectorService.reset();
    return new Promise<any>((resolve, reject) => {
      this.requesterService.request('get', 'auth/logout').subscribe(loggedOut => {
        AuthService.clearDataFromLocalStorage();
        this.authState.next(false);
        resolve(true);
      }, error => {
        AuthService.clearDataFromLocalStorage();
        this.authState.next(false);
        resolve(true);
      });
    });
  }

  public startIdleTimer() {
    if (!this.idleSubscriptionEstablished) {
      this.idleUserDetectorService.idleTimeOut
        .pipe(
          first(isIdle => isIdle === true)
        )
        .subscribe(async (isIdle) => {
          if (isIdle) {
            await this.logout();
            this.router.navigateByUrl("/auth/login");
          }
        });
      this.idleSubscriptionEstablished = true;
    }

  }


}
